import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import dimensions from "../../constants/dimensions"
import breakpoints from "../../constants/breakpoints"
import colors from "../../constants/colors"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { Link } from "gatsby"

const ImageBorder = styled.div`
  overflow: hidden;
  width: auto;
`

const StyledImage = styled(Image)`
  border-style: solid;
  border-width: 1px;
  border-color: rgba(00, 00, 00, 0.2);

  transition: all 700ms ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
`

const LinkToUpload = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 2rem;

  @media (max-width: ${breakpoints.smallmedium}px) {
    flex-direction: column;
    a {
    margin: 2rem 0 !important;
  }
`

const Introduction = styled.section`
  display: flex;
  flex-direction: column;
  margin: 1rem auto 0rem auto;
  max-width: ${dimensions.maxWidth}px;

  @media (max-width: 1080px) {
    width: 90%;
  }

  a {
    background-color: ${colors.vcp};
    border-style: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 0px 2px;
    cursor: pointer;
    width: 250px;
    height: 1.5rem;

    &:hover,
    &:focus {
      background-color: #003a75;
    }
  }
`

const Categories = styled.section`
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin: 1rem auto 5rem auto;
  max-width: ${dimensions.maxWidth}px;

  h2 {
    margin: 1.5rem 0 0.5rem;
    font-family: formalScript;
  }

  h4 {
    margin: 0.5rem 0 0.2rem;
  }

  p {
    margin: 0 0 0.8rem;
  }

  .W50 {
    width: 45%;
  }

  .W70 {
    width: 70%;
  }

  .Center {
    margin: 0 auto;
  }

  .Right {
    margin: 0 0 0 auto;
  }

  @media (max-width: 1080px) {
    width: 90%;
  }
`

const Info = styled.section`
 display: flex;
  flex-direction: column;
  // align-items: center;
  margin: 1rem auto 0rem auto;
  max-width: ${dimensions.maxWidth}px;
  font-family: formalScript;

  h1 {
    font-size: 2rem;
    color: black; //${colors.burgund};
  }
  
  @media (max-width: 1080px) {
    width: 90%;
  }
`

const TwoImages = styled.div`
  display: flex;
  justify-content: space-between;
`

export default class BoF extends React.Component {
  render() {
    return (
      <Layout
        title="Gau Alt-Burgund | Bachelor of Fahrt"
        description="Informationen über unser Bachelor of Fahrt Programm"
      >
        <Info>
          <h1>Lager und Fahrten</h1>
        </Info>

        <Categories>
          <h2>An Land</h2>
          <h4>Die normale Fahrt</h4>
          <p>
            In dieser Kategorie könnt ihr eine klassiche Wochenendfahrt
            unternehmen, ohne großen Schickschnack, einfach die Fahrt genießen.
          </p>
          <TwoImages>
            <ImageBorder className={"W50"}>
              <StyledImage
                fluid={this.props.data.normaleFahrt.childImageSharp.fluid}
                alt="Pfadfinder mit Rucksäcken wandern"
              />
            </ImageBorder>
            <ImageBorder className={"W50"}>
              <StyledImage
                fluid={this.props.data.normaleFahrtX.childImageSharp.fluid}
                alt="Kohten in einer Ruine"
              />
            </ImageBorder>
          </TwoImages>
          <h4>Die Sternfahrt</h4>
          <p>
            Bei einer Sternfahrt einigen sich mehrere Gruppen auf ein
            gemeinsames Fahrtenziel. Es wird ein gemeinsamer Abschlussabend oder
            sogar ein anschließendes Lager veranstaltet.
          </p>
          <h4>Die Gauklerfahrt</h4>
          <p>
            Wie wäre es mit einer Gauklerfahrt? Das Gute: Reisekosten gleich
            Null! Hier müsst ihr alles, was ihr an Geld benötgt, beispielsweise
            ersingen. Also packt die Klampfe ein und los!
          </p>
          <h4>Ohne Zelt unterwegs</h4>
          <p>
            Vielleicht seid ihr ja mal so mutig, euer heißgeliebtes Schwarzzelt
            zu Hause zu lassen. Stellt euch diesem Wagnis!
          </p>
          <ImageBorder className={["W70", "Center"].join(" ")}>
            <StyledImage
              fluid={this.props.data.ohneZelt.childImageSharp.fluid}
              alt="Pfadfinder laufen mit Rucksäcken an einem Ortsschild vorbei"
            />
          </ImageBorder>
          <h4>Die Mottofahrt</h4>
          <p>
            Kreatives Austoben ist hier gefragt! Zum Beispiel mit Schirm und
            Melone (und Charme) als Gentleman die Welt erkunden. Oder ihr spielt
            euren Lieblingsfilm nach...
          </p>
          <h2>Zu Wasser</h2>
          <h4>Der Kanuhajk</h4>
          <p>
            Mietet euch für ein paar Tage Kanus und das Abenteuer kann beginnen!
            Lasst euch bei der Planung ein paar Tipps bspw. vom Fahrten-Uni-Team
            geben.
          </p>
          <ImageBorder className={["W70", "Center"].join(" ")}>
            <StyledImage
              fluid={this.props.data.kanufahrt.childImageSharp.fluid}
              alt="Ein Pfadfinder in Kluft auf einem Kanu"
            />
          </ImageBorder>
          <h4>Der Segeltörn</h4>
          <p>
            Auf, zum Beispiel, der Mytilus segeln zu gehen ist ein Erlebnis, das
            jeder Pfadfinder einmal gemacht haben sollte.
          </p>
          <h2>Der Zufall entscheidet</h2>
          <div style={{ display: "flex" }}>
            <div style={{}}>
              <h4>Die Würfelfahrt</h4>
              <p>
                Das Prinzip ist einfach: Im Fahrtengepäck ist ein Würfel dabei
                und an jeder Kreuzung würfelt ihr, welche Richtung ihr
                einschlagen werdet.
              </p>
              <p>ODER</p>
              <p>
                Das Fahrtenziel entscheidet sich am Bahnhof, je nach dem,
                welches Gleich ihr würfelt.
              </p>
            </div>

            <ImageBorder>
              <StyledImage
                className={"W50 Right"}
                fluid={this.props.data.wuerfelfahrt.childImageSharp.fluid}
                alt="Ein Wegweiser mit vielen Schildern"
              />
            </ImageBorder>
          </div>

          <h4>Das Tramp-Wettrennen</h4>
          <p>
            Oft ist Trampen bei uns verpönt. Bei einem Tramp-Wettrennen kommt es
            aber genau darauf an, so schnell wie möglich vor euren Gegnergruppen
            am Ziel zu sein. Achtung: Man sollte beim Trampen nie alleine
            unterwegs sein!
          </p>
          <h2>Großfahrt oder Auslandsfahrt</h2>
          <p>
            Eine Großfahrt geht länger als ein Wochenende, oft zwei bis drei
            Wochen und das Fahrtenziel befindet sich meist im Ausland. Eine
            Auslandsfahrt will gut vorbereitet sein: Informiert euch vorher über
            kulturelle und gesetzeliche Regeln in eurem Fahrtenland.
          </p>
          <h2>Hajk</h2>
          <h4>Der Osterhajk</h4>
          <p>
            Der überbündische Osterhajk findet, wie der Name schon sagt, um
            Ostern statt. Eine Woche lang bekommt man bei diesem großen
            Geländespiel Rätsel, um Posten anzulaufen und sammelt fleißig
            Punkte. Der Hajk steht unter einem Motto, somit verkleidet man sich
            oft zusätzlich. Man sollte allerdings wissen, das dieser Hajk es
            durchaus in sich hat!
          </p>
          <ImageBorder className={"W70 Center"}>
            <StyledImage
              fluid={this.props.data.osterhajk.childImageSharp.fluid}
              alt="Eine Wanderkarte"
            />
          </ImageBorder>
          <h4>Der Frühlingshajk</h4>
          <p>
            Den Frühlingshajk könnte man vielleicht als den kleinen Bruder des
            Osterhajks bezeichnen. Auch Jüngere dürfen hieran teilnehmen und
            erste Hajkerfahrungen sammeln. Das heißt aber nicht, dass er weniger
            spaßig ist!
          </p>
          <h2>Kulinarisch</h2>
          <h4>Die Schlemmerfahrt</h4>
          <p>
            Auf Fahrt sein bedeutet oft, das Essen ein wenig zu vernachlässigen.
            Nicht bei der Schlemmerfahrt!
          </p>
          <TwoImages>
            <ImageBorder className={"W50"}>
              <StyledImage
                fluid={this.props.data.schlemmerfahrt.childImageSharp.fluid}
                alt="Töpfe mit buntem Essen: Karotten, bunte Paprika, Zwiebeln, Tomaten"
              />
            </ImageBorder>
            <ImageBorder className={"W50"}>
              <StyledImage
                fluid={this.props.data.schlemmerfahrt2.childImageSharp.fluid}
                alt="Ein Holzblock mit Brot, Messer und Kaffee"
              />
            </ImageBorder>
          </TwoImages>
        </Categories>
      </Layout>
    )
  }
}

// graph for story images
export const StoryImage = graphql`
  fragment Image on File {
    childImageSharp {
      fluid(maxWidth: 1024) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

// graph for story images
export const BofImage = graphql`
  fragment ImageBof on File {
    childImageSharp {
      fixed(height: 256) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

// queries
export const query = graphql`
  query {
    kanufahrt: file(relativePath: { eq: "bof/kanuFahrt-min.jpg" }) {
      ...Image
    }
    normaleFahrt: file(relativePath: { eq: "bof/normaleFahrt-min.jpg" }) {
      ...Image
    }
    normaleFahrtX: file(relativePath: { eq: "bof/normaleFahrt2-min.jpg" }) {
      ...Image
    }
    ohneZelt: file(relativePath: { eq: "bof/ohneZelt-min.jpg" }) {
      ...Image
    }
    osterhajk: file(relativePath: { eq: "bof/osterhajk-min.jpg" }) {
      ...Image
    }
    schlemmerfahrt: file(relativePath: { eq: "bof/schlemmerfahrt-min.jpg" }) {
      ...Image
    }
    schlemmerfahrt2: file(relativePath: { eq: "bof/schlemmerfahrt2-min.jpg" }) {
      ...Image
    }
    wuerfelfahrt: file(relativePath: { eq: "bof/wuerfelfahrt-min.jpg" }) {
      ...Image
    }
    bofLogo: file(relativePath: { eq: "logo-min.png" }) {
      ...ImageBof
    }
    bofWanderer: file(relativePath: { eq: "wanderer.png" }) {
      ...ImageBof
    }
  }
`
